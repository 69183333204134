import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundSection from "../components/Globals/BackgroundSection"
import Info from '../components/Home/Info'

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="Home"/>
    <BackgroundSection
      img={data.img.childImageSharp.fluid}
      title="About us"
      styleClass="about-background"/>
  </Layout>
)

export const query = graphql`
{
  img: file(relativePath: {eq: "about-background.jpg"}) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 2000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`

export default AboutPage
